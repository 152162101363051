.download{
    height:55px;
    width:55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    top:93%;
    right:2%;
    z-index:5;
}
.download img{
    padding-left: 11px;
}
.download:hover{
    background-color: rgba(34, 64, 64, 0.608);
}

