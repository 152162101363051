/* .btn button{
    margin-top:5px;
    background-color: #059669; 
    border-radius: 10px;
    color:white;
    font-size:20px;
    margin-bottom:10px;
} */

.event{
    -webkit-column-count: 3;
    -moz-column-count: 3; 
      column-count: 3; 
      -webkit-column-width: 25%;
    -moz-column-width: 25%; 
      column-width: 25%;
      padding:50px;
}
.event >.post > img{
    border-radius:12px;
}


@media (max-width:950px){
    .event {
        -webkit-column-count: 3;
        -moz-column-count: 3; 
        column-count: 3; 
        -webkit-column-width: 35%;
        -moz-column-width: 35%; 
        column-width: 35%;
        -webkit-column-gap: 5px;
        column-gap: 5px;
        padding: 20px; 
    }
    .post{
        padding:20px;
    }
    .post img{
        margin-top: 20px;
    }
}
@media (max-width:500px){
    .event {
        -webkit-column-count: 1;
        -moz-column-count: 1; 
        column-count: 1; 
        -webkit-column-width: 35%;
        -moz-column-width: 35%; 
        column-width: 35%;
        -webkit-column-gap: 5px;
        column-gap: 5px;
        padding: 20px; 
    }
    .post img{
        margin-top: 10px;
        
    }
}