.gallery {
     -webkit-column-count: 4;
    -moz-column-count: 4; 
      column-count: 4; 
      /* -webkit-column-width: 25%;
    -moz-column-width: 25%; 
      column-width: 25%; */
    padding: 20px 50px; 

}
.gallery .pics img{
    object-fit: cover;
    width: 100%; 
    height: 100%;
}
.gallery .pics img{
    border-radius: 8px;
    padding-top:10px;
    filter: grayscale(100%);
}

.pics img{
    margin-top: 10px;
    transition: 0.5s ease-in-out;
    
}
.pics img:hover{
    transform:scale(1.08);
    z-index:3;  
    filter: grayscale(0%);

}

.model{
    height: 100vh;
    width: 100%;
    position:fixed;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition:opacity 0.4s ease,visibility 0.4s ease,transform 0.5s ease-in-out ;
    visibility: hidden;
    opacity: 0;
    transform:scale(0) ;
    overflow: hidden;
    z-index:3;

}
.model.open{
    visibility: visible;
    opacity: 1;
    transform:scale(1) ;
}
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: box;
    box-sizing: border-box;
    line-height: 0;
    padding: 20px 0 20px;
    margin:0 auto;
}
.model.open .close{
    position:absolute;
    top:5px;
    right:15px;
}

.btn_show button{
    position:relative;
    left:80%;
    width:15%;
    height:28px;
    border-radius:5px;
    margin-bottom:20px;
    color:white;
    font-size:95%;
}

@media (max-width:600px){
    .gallery {
        -webkit-column-count: 3;
        -moz-column-count: 3; 
        column-count: 3; 
        -webkit-column-width: 35%;
        -moz-column-width: 35%; 
        column-width: 35%;
        /*-webkit-column-gap: 5px;
        column-gap: 5px;*/
        padding: 20px; 
    }
    .pics img{
        margin-top: 10px;
        
    }
    .btn_show button{
        position:relative;
        left:35%;
        width:30%;
        height:22px;
        color:white;
        font-size:85%;
    }
}

/*
<div className=' bg-bg1 h-screen w-full items-center justify-center text-white flex'>
     <div className='p-4 bg-black h-5/5 w-4/4 shadow-2xl items-center justify-center '>
      <Gallery/>
    </div> 
    </div>*/

/*.gallery {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
}
.gallery > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery > div > img {
    width: 100vh;
    height: 100vh;
    object-fit: cover;
    border-radius: 5px;
}*/






