.write {
  background-color: rgba(34, 34, 34, 0.632);
  color: rgb(255, 252, 252);
  height: 100%;
  width: 100%;
  transform: translateY(-100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0%;
  transition: 0.5s ease-in-out;
}
.write .about {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 5%;
  background: transparent;
}
.write .name {
  font-size: 18px;
  background: transparent;
}
.write .link {
  margin-top: 10%;
  background-color: transparent;
  display: flex;
}
.link img {
  background-color: transparent;
  display: inline-flex;
  justify-content: space-around;
}
.write:hover {
  opacity: 100%;
}

.others {
  height: 300px;
  margin-top: 10px;
}
.otherMembers{
  width:100%;
  display:flex;
  justify-content: center;
}
.btn_others{
  height:35px;
  width:50%;
  margin-top:20px;
  padding:5px;
}

