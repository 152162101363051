/*.up{
    -webkit-column-count: 3;
    -moz-column-count: 3; 
      column-count: 3; 
    padding: 20px 50px 0px; 
}*/
.main_head{
  text-align: center;
}
.up_main{
  width:600px;
  height:400px;
  display:flex;
  padding: 10px;
  flex-direction:row;
  justify-content: center;
  background-color: rgb(60, 60, 60);
  border-radius: 10px;
  margin-right: 30px;
}
.up_main .up_pic{
  margin-right: 20px;
  border-radius: 10px;
  height: auto;
  width: 70%;
}
.up_main .up_pic img{
  border-radius: 10px 0 0 10px;
  height: 100%;
  width: 100%;
}
.up_write{
  display:inline-flex;
  flex-direction:column;
  padding-right: 20px;
  justify-content: space-evenly;
  width:60%;
  height: 100%;
  background-color: transparent;
  color:white;
  position:relative;
}
hr{
  width: 30%;
  height:5%;
  border-radius:5px ;
}
.upFront{
  display:flex;
  justify-content: center;
}
.carousel{
  width:400px;
  height:400px;
  border-radius: 10px ;
  background-color: rgb(60, 60, 60);
  display: flex;
  flex-direction: column;
}
.carousel_bottom{
  font-size: 40px;
  font-weight: bold;
  color:white;
  display: flex;
  justify-content: center;
}
.guest_vid{
  margin-left: 100px;
}

@media (max-width:1100px){
  .carousel{
    width:300px;
  }
  .up_main{
    width: 500px;
  }
  .guest_vid{
    margin-left: 50px;
  }
}

@media (max-width:850px){
  .upFront{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .carousel{
    width:400px;
    margin-top: 20px;
  }
  .up_main{
      width:400px;
      height:600px;
      display:flex;
      flex-direction:column;
      justify-content:center;
      margin-right: 0;
  }
  .guest_vid{
    margin-left: 100px;
  }
  .up_main .up_pic {
    width: 330px;
    height: 320px;
    margin-left: 20px;
  }
  .up_main .up_pic img{
      border-radius: 10px;
  }
  .head{
      margin-bottom:20px;
  }
  .body{
      margin-bottom:20px; 
  }
  .up_write{
      width:100%;
      padding:10px;
  }
  .arrow img{
      position:absolute;
      top:80%;
      left:80%;
  }
}


@media (max-width: 500px) {
  .up_main {
    width: 300px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
  }
  .guest_vid{
    margin-left: 50px;
  }
  .carousel{
    width:300px;
    margin-top: 20px;
  }
  .up_main .up_pic {
    width: 230px;
    height: 260px;
    margin-left: 20px;
  }
  .up_main .up_pic img {
    border-radius: 10px;
  }
  .head {
    margin-bottom: 20px;
  }
  .body {
    margin-bottom: 20px;
  }
  .up_write {
    width: 100%;
    padding: 10px;
  }
  .arrow img {
    position: absolute;
    top: 80%;
    left: 80%;
  }
}