.ev_page_main {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ev_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.ev_top > .hero_pic {
  height: 400px;
  width: 60%;
}
.ev_top > .hero_pic > img {
  height: 100%;
}
.ev_right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left:20px;
}
.ev_bottom {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.ev_right > p {
  font-size: 18px;
}

.finals {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;
  /*margin:20px 0 0 170px;*/
}

.finals > span {
  font-size: 20px;
}
.btn{
  width:100%;
}
.btn_2{
  border:2px solid white;
  width:100%;
}
@media (max-width: 600px) {
  .ev_top {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .ev_top > .hero_pic {
    height: 400px;
    width: 80%;
  }
  .ev_right {
    margin: 20px;
    width: 80%;
  }
  .ev_bottom {
    width: 80%;
  }
  .btn_2{
    margin-top:10px;
  }
}
/*{eventData[id.id - 1].formUrl.length > 5 && ( 
              <Link to={eventData[id.id - 1].formUrl}>
                <button className="btn bg-white text-black rounded-md font-bold px-4 py-2 font-font1 ">
                {eventData[id.id - 1].btnName}
                </button>
              </Link>
            )}*/